import { MouseEventHandler } from 'react';
import classNames from 'classnames';
import Image from '@next/image';

import { Classes } from 'types/Classes';
import DotsIcon from 'assets/icons/dots-dark.svg';
import ArrowIcon from 'assets/icons/arrow.svg';
import PossibleLink from '@components/atoms/PossibleLink';
import Typography from '@components/atoms/Typography';
import getSearchAliasHref from 'utils/getSearchAliasHref';
import getCategoryHref from 'utils/getCategoryHref';
import { Menu } from 'types/models/Menu';

import classes from './MenuItem.module.scss';

export type Props = Partial<Omit<Menu, 'id' | 'subcategories'>> & {
  isMobile?: boolean;
  classes?: Classes<'root' | 'icon'>;
  hasSubcategories?: boolean;
  // Mobile Props
  setActiveMenu?: MouseEventHandler<HTMLDivElement>;
  // Desktop Props
  isActive?: boolean;
  isDropdownOpened?: boolean;
  onMouseEnter?: () => void;
  onClick?: (e: Event) => void;
};

const MenuItem = ({
  classes: customClasses,
  text = '',
  image,
  searchAlias,
  category,
  settings,
  isMobile,
  isActive,
  isHighlighted,
  isDropdownOpened,
  hasSubcategories,
  setActiveMenu,
  onMouseEnter,
  onClick,
}: Props): JSX.Element => {
  const href = getCategoryHref(category?.slug) || getSearchAliasHref(searchAlias) || settings?.url;

  return (
    <div
      className={classNames(classes.wrapper, customClasses?.root, {
        [classes.highlight]: isHighlighted,
        [classes.openedWrapper]: isDropdownOpened,
        [classes.activeWrapper]: isActive,
      })}
      data-eid="menu-item"
      onMouseEnter={onMouseEnter}
    >
      <PossibleLink component="span" className={classes.itemWrapper} href={href} onClick={onClick}>
        <div className={classes.item}>
          <div className={classes.itemChild}>
            {image && (
              <Image
                objectFit="contain"
                hasFade={false}
                hasLazyLoad={false}
                hasBlendMode={false}
                alt={text}
                className={classNames(classes.icon, customClasses?.icon, {
                  [classes.highlightIcon]: isHighlighted,
                })}
                resolveConfig={{
                  preset: 'main_menu_icon',
                  productName: 'main_menu_icon',
                  extension: image.extension,
                  name: image.basename,
                  description: text,
                }}
              />
            )}
            <PossibleLink href={href}>
              <Typography variant={isMobile ? 'body1' : 'body2'} className={classes.text}>
                {text}
                {hasSubcategories && <ArrowIcon className={classes.arrow} height={8} width={4} />}
              </Typography>
            </PossibleLink>
          </div>
          {isMobile && (
            <div
              className={classes.dotIcon}
              onClick={hasSubcategories ? setActiveMenu : undefined}
              data-eid={hasSubcategories ? 'open-subcategories' : undefined}
            >
              <DotsIcon />
            </div>
          )}
        </div>
      </PossibleLink>
    </div>
  );
};

export default MenuItem;
