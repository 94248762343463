import { useState } from 'react';

import { Menu } from 'types/models/Menu';
import { mainMenuRoute } from 'apiRoutes/mainMenu';
import useFetch from 'hooks/useFetch';

type Props = {
  mainMenu: Menu[];
};

const useMainMenuState = ({ mainMenu }: Props) => {
  const [localMainMenu, setLocalMainMenu] = useState<Menu[]>(mainMenu);
  const [loadedMenuMap, setLoadedMenuMap] = useState<Record<number, boolean>>({});

  const [getMenu] = useFetch(mainMenuRoute);

  const loadMenuItem = async (id: number): Promise<Menu | undefined> => {
    if (loadedMenuMap[id]) {
      return undefined;
    }

    const { data } = await getMenu({ id });

    if (data) {
      setLocalMainMenu((currentMenu) =>
        currentMenu.map((currentMenuItem) => (currentMenuItem.id === id ? data : currentMenuItem))
      );
    }

    setLoadedMenuMap((currentMenu) => ({ ...currentMenu, [id]: true }));

    return data;
  };

  const isMenuItemLoaded = (id: number) => !!loadedMenuMap[id];

  return {
    menuItems: localMainMenu,
    isMenuItemLoaded,
    loadMenuItem,
  };
};

export default useMainMenuState;
