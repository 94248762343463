import { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import config from 'config';
import { Menu } from 'types/models/Menu';
import { SEARCH_WRAPPER_ID } from '@components/MainHeader';
import { CART_ID } from '@components/Cart';
import Loader from '@components/atoms/Loader';
import Container from '@components/atoms/Container';
import { scrollController } from 'utils/scrollController';
import useElementHeight from 'hooks/useElementHeight';
import useOutsideClick from 'hooks/useOutsideClick';
import useBoolean from 'hooks/useBoolean';
import useTimeout from 'hooks/useTimeout';

import MenuItem from '../components/MenuItem';
import MenuAreas from '../components/MenuAreas';
import useMainMenuState from '../useMainMenuState';
import classes from './MainMenu.module.scss';

type Props = {
  mainMenu: Menu[];
};

const { OVERLAY_ID } = config;

const MainMenu = ({ mainMenu }: Props): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { menuItems, isMenuItemLoaded, loadMenuItem } = useMainMenuState({ mainMenu });
  const [activeMenu, setActiveMenu] = useState<Menu | null>(null);
  const [isDropdownOpened, { on: openDropdown, off: closeDropdown }] = useBoolean(false);
  const dropdownHeight = useElementHeight(dropdownRef, [activeMenu]);
  const { start: startTimeout, clear: clearTimeout } = useTimeout(300);
  const closeMenuDropdown = useCallback(() => {
    closeDropdown();
    startTimeout(() => setActiveMenu(null));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onClick = (item: Menu) => async (e: Event) => {
    if (!item.subcategoriesAggregate?.aggregate.count) {
      return;
    }

    e.preventDefault();
    if (activeMenu === item) {
      closeMenuDropdown();
    } else {
      clearTimeout();
      setActiveMenu(item);
      openDropdown();
    }

    const data = await loadMenuItem(item.id);

    if (data) {
      setActiveMenu(data);
    }
  };

  useEffect(() => {
    const overlay = document.getElementById(OVERLAY_ID);
    const searchWrapper = document.getElementById(SEARCH_WRAPPER_ID);
    const cart = document.getElementById(CART_ID);

    if (isDropdownOpened) {
      scrollController.disableScroll();
      overlay?.setAttribute('style', 'opacity: 1; height: 100%');
      searchWrapper?.setAttribute('style', 'z-index: 102;');
      cart?.setAttribute('style', 'z-index: 102;');
    } else {
      overlay?.setAttribute('style', 'opacity: 0; height: 100%');
      scrollController.enableScroll();
      setTimeout(() => {
        overlay?.setAttribute('style', 'opacity: 0; height: 0');
        searchWrapper?.removeAttribute('style');
        cart?.removeAttribute('style');
      }, 150);
    }
  }, [isDropdownOpened]); // eslint-disable-line react-hooks/exhaustive-deps

  useOutsideClick(ref, () => {
    closeMenuDropdown();
  });

  return (
    <div className={classNames(classes.wrapper, { [classes.opened]: isDropdownOpened })} ref={ref} data-nosnippet>
      <Container className={classes.container} renderAs="nav">
        <div className={classes.menuItemsWrapper}>
          <div className={classes.menuItems}>
            {menuItems.map((item) => (
              <MenuItem
                key={item.id}
                {...item}
                isDropdownOpened={isDropdownOpened}
                hasSubcategories={!!item.subcategoriesAggregate?.aggregate.count}
                isActive={isDropdownOpened && activeMenu?.id === item.id}
                onClick={onClick(item)}
              />
            ))}
            <div
              data-eid="menu-popup-wrapper"
              className={classes.popupWrapper}
              style={isDropdownOpened ? { height: dropdownHeight !== 'auto' ? dropdownHeight : undefined } : {}}
            >
              {activeMenu && (
                <div ref={dropdownRef} className={classes.popup}>
                  {isMenuItemLoaded(activeMenu.id) ? (
                    <MenuAreas items={activeMenu.subcategories || []} closeDropdown={closeMenuDropdown} />
                  ) : (
                    <div className={classes.loaderWrapper}>
                      <Loader size={50} isDark />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default MainMenu;
