import dynamic from 'next/dynamic';

import Loader from '@components/atoms/Loader';

import classes from './MenuAreas.module.scss';

const MenuAreas = dynamic(() => import('./MenuAreas'), {
  ssr: true,
  loading: () => (
    <div className={classes.loaderWrapper}>
      <Loader size={50} isDark />
    </div>
  ),
});

export default MenuAreas;
