import { appContainerId } from 'consts/app';

class ScrollController {
  isScrollable = true;

  appId = appContainerId;

  get $app() {
    return document.getElementById(this.appId);
  }

  disableScroll = (): void => {
    if (this.isScrollable && this.$app) {
      this.$app.scrollTo(0, 0);
      this.$app.style.overflowY = 'hidden';
      this.isScrollable = false;
    }
  };

  enableScroll = (): void => {
    if (!this.isScrollable && this.$app) {
      this.$app.style.overflowY = '';
      this.isScrollable = true;
    }
  };
}

export const scrollController = new ScrollController();
