import { defineMessages } from 'utils/intl';

export default defineMessages({
  home: 'Home',
  faq: 'Pytania i odpowiedzi',
  complexDelivery: 'Kompleksowe dostawy',
  aboutUs: 'O nas',
  publicSector: 'Sektor publiczny',
  contact: 'Kontakt',
  privacyPolicy: 'Polityka prywatności',
  licensePolicy: 'Polityka licencyjna',
  cookiePolicy: 'Polityka cookies',
  termsOfService: 'Regulamin',
  termsOfSales: 'Regulamin sprzedaży',
  compare: 'Porównanie produktów',
  search: 'Wyniki wyszukiwania',
  articles: 'Artykuły',
  conferences: 'Szkolenia IT',
  accessories: 'Akcesoria',
  documents: 'Dokumenty prawne',
  showMore: 'pokaż więcej',
});
